<template>
    <b-modal id="modal-termos" hide-footer size="lg">
        <div class="spaceModal">
            <div class="header">
                <div class="title">Termos de Uso</div>
                <div class="subtitle">É necessário estar ciente aos termos de uso!</div>
            </div>
            <div class="lineDiv"></div>
            <div v-if="step === 0" class="animationOpacity2">
                <b-form-group label="Vídeo de Introdução" label-for="descricao">
                    <iframe
                        class="imagem"
                        height="100%"
                        v-if="techOrder === 'panda'"
                        width="100%"
                        :src="midiaTermosUrl"
                        style="border: 0; pointer-events: all; height: 45vh"
                    ></iframe>
                    <video ref="videoPlayer"
                        v-else-if="techOrder !== 'iframe'"
                        class="video-js widthVideoPlayer" 
                        :tech-order="techOrder" 
                        :autoplay="autoplay" 
                        :controls="true"
                        v-on:pause="onVideoPause"
                        @changed="playerStateChanged($event)"
                        >
                        <source :src="src" :type="type">
                    </video>
                    <div v-html="src" v-else>

                    </div>
                </b-form-group>
            </div>
            <div v-if="step === 1" class="animationOpacity2">
                <b-form-group label="Termos de Uso" label-for="descricao">
                    <div id="spaceText" class="spaceText">
                        <span v-html="termosPainel"></span>
                    </div>
                </b-form-group>
            </div>
            <div class="footer animationOpacity" v-if="step === 0">
                <button class="next" @click="next">Próxima etapa</button>
            </div>
            <div class="footer animationOpacity" v-if="step === 1">
                <button class="prev" v-if="existVideo" @click="prev">Voltar</button>
                <button class="next" @click="salvarDados()">Confirmar</button>
            </div>
        </div>
    </b-modal>    
</template>

<script>
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm';
import 'videojs-youtube';
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();

export default {
    components:{
  },
props:['siteid'],
  data(){
    return{
        termosPainel:'',
        midiaTermos:null,
        midiaTermosUrl:null,
        mediaTypeLesson:'',
        step:0,
        autoplay: true,
        player: null,
        techOrder: "youtube",
        type: "video/youtube",
        src: "",
        statusCheck:false,
        toEnd:false,
        existVideo:true,
    }
  },
  methods:{
    scrollFunction () {
        let element = document.getElementById('spaceText');
        if(element.scrollHeight - element.scrollTop === element.clientHeight){
            this.toEnd = true;
        }else{
            this.toEnd = false;
        }
    },
    reachedEndOfText(reached){
        if (reached) {
            this.toEnd = true;
            //console.log('to end xxxxxxxxxxxx')  
        }
    },
    salvarDados(){
        this.$root.$emit('loadOn');
        let data = {
            id: '/meta/_termos_'+this.siteid,
            value:'accepted'
        }
        serviceMember
        .postID(data)
        .then((resp) => {
          //console.log("accept terms", resp);
          this.$root.$emit('bv::hide::modal', 'modal-termos', '#btnShow')
          this.$root.$emit('loadOff');
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    prev(){
        this.getMetaTermosVideo();
        this.step = this.step-1;
    },
    next(){
        this.step = this.step+1
    },
    getMetaTermosVideo(){
      this.$root.$emit('loadOn');
      serviceMeta
        .search('keys[]=introductoryVideo')
        .then((resp) => {
            //console.log('xxxxxxxxx', resp, JSON.parse(resp.introductoryVideo));
            var data = JSON.parse(resp.introductoryVideo);
            if(resp.introductoryVideo === null || data.url === null){
                this.step = 1;
                this.existVideo = false;
            }else{
                //console.log("meta video termos", data);
                this.midiaTermos = data.thumb;
                this.midiaTermosUrl = data.url;
                this.src = data.url;
                this.techOrder = data.type;
                if(data.type === 'vimeo'){
                    this.type = "video/vimeo";
                }else if(data.type === 'youtube'){
                    this.type = "video/youtube";
                }else{
                    return
                }
                //console.log(this.src, this.type);
                setTimeout(() => {
                    this.getPlayer();
                }, 1000);
            }
          this.$root.$emit('loadOff');
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit('loadOff');
        });
    },
    getMetaTermosTxt(){
      serviceMeta
        .search('keys[]=termsOfUse')
        .then((resp) => {
          //console.log("meta txt termos", resp);
          this.termosPainel = resp.termsOfUse;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    showModalUpload(){
      this.$root.$emit("videotermos");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    playerDispose(){ 
        this.player.dispose(); 
    },
    playerGetTime(){
        return this.player.currentTime();
    },
    getPlayer(){
        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
            //console.log('onPlayerReady', this);
        });
        //console.log("xxx", this.player);
    },
    onVideoPause: function () {
        //console.log('video is paused')
    },
    play() {
        this.player.play();
    },
    pause() {
        this.player.pause();
    },
    playerStateChanged: function(playerCurrentState) {
        //console.log('player current update state', playerCurrentState);
    },
  },
  mounted(){
    this.getMetaTermosTxt();
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId === 'modal-termos'){
          this.getMetaTermosTxt();
          this.getMetaTermosVideo();
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.spaceModal{
    padding: 40px;
    margin-top: -40px;
}

.hide{
    display: none !important;
}

.disableBtn{
    background: #f7f7f7 !important;
    border: 1px solid #f7f7f7 !important;
    color: #81858E !important;
    pointer-events: none;
}

.spaceText{
    height: 30vh;
    overflow: auto;
}

.footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.next{
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    height: 45px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    width: 251.29px;
}
.prev{
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #81858E;
    width: 180px;
    height: 45px;
    margin-right: 10px;
}

.btn-openModal{
    background: var(--maincolortrans);
    border: 2px dashed var(--maincolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    cursor: pointer;
    .text{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor) !important;
        display: flex;
        justify-content: center;
        margin-top: 17px;
    }
}

.btn-openModalPreview{
      background: var(--maincolortrans);
    border: 2px dashed var(--maincolor);
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
    img{
        border-radius: 3px;
    }
    .imagePreviewThumb{
        height: 150px;
    }
}

.lineDiv{
    width: 100%;
    height: 0.5px;
    background: #E5E5E5;
    transform: matrix(1, 0, 0, -1, 0, 0);
    margin-top: 30px;
    margin-bottom: 30px;
}
.header{
    .title{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: #333333;
        margin-bottom: 5px;
    }
    .subtitle{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #81858E;
    }
}
</style>
