<template>
  <b-modal id="modal-custom-fields" hide-footer size="lg">
    <div class="spaceModal">
      <div class="header">
        <div class="title">Preencha os campos abaixo</div>
        <div class="subtitle">
          É necessario preencher os campos para ter acesso a plataforma.
        </div>
      </div>
      <div class="lineDiv"></div>
      <div>
        <div v-for="(find, index) in finds" :key="find.label">
          <b-form-group :label="find.name" name-for="name">
            <b-form-input
              v-if="find.type === 'text'"
              v-model="find.value"
              :placeholder="find.name"
              :key="index"
            ></b-form-input>
            <b-form-textarea
              v-if="find.type === 'textarea'"
              v-model="find.value"
              :placeholder="find.name"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <div class="footer">
        <button @click="salvarDados">Salvar</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import CustomFieldsService from "@/services/resources/CustomFieldsService";
const service = CustomFieldsService.build();
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import MemberMetaService from "@/services/resources/MemberMetaService";
const serviceMemberMeta = MemberMetaService.build();
import notify from "@/services/libs/notificacao";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      finds: [],
      memberId: null,
      siteId: null,
    };
  },
  methods: {
    getCurrentSiteAndMember() {
      serviceMember
        .read("/me")
        .then((resp) => {
          this.siteId = resp.current.currentSite.id;
          this.memberId = resp.current.currentMember.id;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    salvarDados() {
      //console.log(this.finds);
      var campos = this.finds;
      for (let i = 0; i < campos.length; i++) {
        const campo = campos[i];

        let data = {
          id:
            "/site/" +
            this.memberId +
            "/" +
            this.siteId +
            "/" +
            campo.name,
          value: campo.value,
        };

        this.$root.$emit("loadOn");
        serviceMemberMeta
          .postID(data)
          .then((resp) => {
            //console.log("save member meta", resp);
            Cookies.set("userCustomFields", true);
            this.$store.dispatch("updateUserCustomFields");
            this.$root.$emit("bv::hide::modal", "modal-custom-fields", "#btnShow");
            this.$root.$emit("loadOff");
          })
          .catch((err) => {
            //console.log(err);
            this.$root.$emit("loadOff");
          });
      }
    },
    getCustomFields() {
      service
        .search()
        .then((resp) => {
          //console.log("get-fields", resp);
          this.finds = resp;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  mounted() {
    this.getCurrentSiteAndMember();
    this.getCustomFields();
  },
};
</script>
<style lang="scss">
label,
legend {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--fontcolor);
}
#modal-custom-fields {
  input,
  textarea,
  select {
    border: 1px solid var(--bordercolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    overflow: hidden !important;
    resize: none !important;
  }
  input:hover,
  select:hover,
  textarea:hover,
  input:focus,
  select:focus,
  textarea:focus {
    border: 1px solid var(--maincolor);
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
  }
}
</style>

<style lang="scss" scoped>
.spaceModal {
  padding: 40px;
  margin-top: -40px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    height: 45px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #ffffff;
    width: 251.29px;
  }
}

.btn-openModal {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 5px;
  height: 55px;
  cursor: pointer;
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--maincolor) !important;
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }
}

.btn-openModalPreview {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  img {
    border-radius: 3px;
  }
  .imagePreviewThumb {
    height: 150px;
  }
}

.lineDiv {
  width: 100%;
  height: 0.5px;
  background: #e5e5e5;
  transform: matrix(1, 0, 0, -1, 0, 0);
  margin-top: 30px;
  margin-bottom: 30px;
}
.header {
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #333333;
    margin-bottom: 5px;
  }
  .subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #81858e;
  }
}
</style>
